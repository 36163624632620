import React, { useState, useEffect} from 'react'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import { ScrollAndShow } from '../../components/scroll-and-show'
import { Layout } from '../../components/layout'
import { useTranslation, Trans } from 'react-i18next'
import { useNews } from '../../hooks/useNews'
import { News as NewsType } from '../../types/news'
import { SEO } from '../../components/seo'

const Page = ({ params }: any) => {
	const [t, i18n] = useTranslation()
	const { news } = useNews()
	const [newsItem, setNewsItem] = useState<NewsType | null>(null)
	const [currentIndex, setCurrentIndex] = useState(0)

	useEffect(() => {
		const newsItem = news.find(newsItem => newsItem.id === params._id)
		if (newsItem) {
			setNewsItem(newsItem)
			const newsId = newsItem.id
			const index = news.findIndex(newsItem => newsItem.id === newsId)
			setCurrentIndex(index)
		}
	}, [params, news])

	return (
		<Layout>
			<SEO
				title={`${newsItem ? newsItem.title : 'NEWS'} | RUBIA ルビア 渋谷メキシカン【朝食・ランチ・バー】`}
				description={newsItem && newsItem.content.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'')}
				keywords={['メキシコ料理', '渋谷' , 'ファインメキシカン', 'ルビア', 'sarasa']}
			/>
			<h1 className="h1">{newsItem ? newsItem.title : 'NEWS'} | RUBIA ルビア / DJ sarasa</h1>
			<div className="news-page">
				<ScrollAndShow>
					<div className="news-text font-lato">NEWS</div>
				</ScrollAndShow>

				{newsItem && (
					<>
						<div className={`${i18n.language === 'en' && newsItem.titleEn ? 'font-lato' : 'font-yu'}`}>
							<ScrollAndShow>
								<div className="news-title">
									{i18n.language === 'en' && newsItem.titleEn || newsItem.title}
								</div>
								<div className="news-date font-lato">{format(new Date(newsItem.date), 'yyyy.MM.dd')}</div>
							</ScrollAndShow>
							<ScrollAndShow>
								<div className="content" dangerouslySetInnerHTML={{
									__html: i18n.language === 'en' && newsItem.contentEn || newsItem.content
								}} />
							</ScrollAndShow>
						</div>
						<ScrollAndShow>
							<div className="pagination-buttons font-lato">
								{news[currentIndex - 1] ? (
									<Link to={`/news/${news[currentIndex - 1].id}`}>← PREV</Link>
								) : (
									<a className="disabled">← PREV</a>
								)}
								{news[currentIndex + 1] ? (
									<Link to={`/news/${news[currentIndex + 1].id}`}>NEXT →</Link>
								) : (
									<a className="disabled">NEXT →</a>
								)}
							</div>
						</ScrollAndShow>

						<ScrollAndShow>
							<div className="past-news-text">PAST NEWS</div>
						</ScrollAndShow>
						<ScrollAndShow>
							<div className="inner">
								{news.map((newsItem, index) => (
									<Link to={`/news/${newsItem.id}`}>
										<div className="news-row" key={index}>
											<div className="font-lato inner-date">{format(new Date(newsItem.date), 'yyyy.MM.dd')}</div>
											<div className={`${i18n.language === 'en' && newsItem.titleEn ? 'font-lato' : 'font-yu'} inner-title`}>
												{i18n.language === 'en' && newsItem.titleEn || newsItem.title}
											</div>
										</div>
									</Link>
								))}
							</div>
						</ScrollAndShow>
						
						<ScrollAndShow>
							<div className="more-button">
								<Link to="/news" className="font-lato">MORE</Link>
							</div>
						</ScrollAndShow>
					</>
				)}
			</div>
		</Layout>
	)
}

export default Page

